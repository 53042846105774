import React from "react"

// Comps
import Container from "../components/container/Container"
import Header from "../components/headers/Header"
import Metas from "../components/meta/Meta"

// Parts
import Panel404 from '../components/panels/Panel404';

const QuatreCentQuatrePage = () => {
    return (
        <Container>
            <Metas />
            <Header />
            <Panel404 />
        </Container>
    )
}

export default QuatreCentQuatrePage
